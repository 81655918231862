function init () {
  /*
    PagePiling 
  /*---------------------------------------*/
  if (document.getElementById('pagepiling')) {

    $('#pagepiling').pagepiling({
      menu: null,
      direction: 'vertical',
      verticalCentered: true,
      sectionsColor: [],
      anchors: [],
      scrollingSpeed: 500,
      easing: 'swing',
      loopBottom: false,
      loopTop: false,
      css3: true,
      navigation: {
        'textColor': '#000',
        'bulletsColor': '#000',
        'position': 'right',
        // 'tooltips': ['section1', 'section2', 'section3', 'section4']
      },
      normalScrollElements: null,
      normalScrollElementTouchThreshold: 5,
      touchSensitivity: 5,
      keyboardScrolling: true,
      sectionSelector: '.home-section',
      animateAnchor: false,
      afterLoad: function(anchorLink, index){},
      onLeave: function(index, nextIndex, direction){
        $('.pp-tableCell').fadeOut();
      },
      afterLoad: function(anchorLink, index){
        $('.pp-tableCell').fadeIn();
      },
    })
  
  }

}

document.addEventListener('DOMContentLoaded', init)
